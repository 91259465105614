exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-en-search-tsx": () => import("./../../../src/pages/en/search.tsx" /* webpackChunkName: "component---src-pages-en-search-tsx" */),
  "component---src-pages-haku-tsx": () => import("./../../../src/pages/haku.tsx" /* webpackChunkName: "component---src-pages-haku-tsx" */),
  "component---src-pages-nopage-tsx": () => import("./../../../src/pages/nopage.tsx" /* webpackChunkName: "component---src-pages-nopage-tsx" */),
  "component---src-pages-sv-sok-tsx": () => import("./../../../src/pages/sv/sok.tsx" /* webpackChunkName: "component---src-pages-sv-sok-tsx" */),
  "component---src-templates-awards-categories-tsx": () => import("./../../../src/templates/Awards/Categories.tsx" /* webpackChunkName: "component---src-templates-awards-categories-tsx" */),
  "component---src-templates-awards-category-tsx": () => import("./../../../src/templates/Awards/Category.tsx" /* webpackChunkName: "component---src-templates-awards-category-tsx" */),
  "component---src-templates-awards-single-tsx": () => import("./../../../src/templates/Awards/Single.tsx" /* webpackChunkName: "component---src-templates-awards-single-tsx" */),
  "component---src-templates-board-tsx": () => import("./../../../src/templates/Board.tsx" /* webpackChunkName: "component---src-templates-board-tsx" */),
  "component---src-templates-books-books-tsx": () => import("./../../../src/templates/Books/Books.tsx" /* webpackChunkName: "component---src-templates-books-books-tsx" */),
  "component---src-templates-books-single-tsx": () => import("./../../../src/templates/Books/Single.tsx" /* webpackChunkName: "component---src-templates-books-single-tsx" */),
  "component---src-templates-contact-tsx": () => import("./../../../src/templates/Contact.tsx" /* webpackChunkName: "component---src-templates-contact-tsx" */),
  "component---src-templates-default-tsx": () => import("./../../../src/templates/default.tsx" /* webpackChunkName: "component---src-templates-default-tsx" */),
  "component---src-templates-events-events-tsx": () => import("./../../../src/templates/Events/Events.tsx" /* webpackChunkName: "component---src-templates-events-events-tsx" */),
  "component---src-templates-events-single-tsx": () => import("./../../../src/templates/Events/Single.tsx" /* webpackChunkName: "component---src-templates-events-single-tsx" */),
  "component---src-templates-home-tsx": () => import("./../../../src/templates/Home.tsx" /* webpackChunkName: "component---src-templates-home-tsx" */),
  "component---src-templates-posts-posts-tsx": () => import("./../../../src/templates/Posts/Posts.tsx" /* webpackChunkName: "component---src-templates-posts-posts-tsx" */),
  "component---src-templates-posts-single-tsx": () => import("./../../../src/templates/Posts/Single.tsx" /* webpackChunkName: "component---src-templates-posts-single-tsx" */)
}

