"use strict";

exports.onRouteUpdate = function (_ref, pluginOptions) {
  var _document$cookie$matc;
  var location = _ref.location;
  if (pluginOptions === void 0) {
    pluginOptions = {};
  }
  // Check if cookie consent exists
  var consent = ((_document$cookie$matc = document.cookie.match('(^|;)\\s*' + 'gw_cookie_consent' + '\\s*=\\s*([^;]+)')) === null || _document$cookie$matc === void 0 ? void 0 : _document$cookie$matc.pop()) || '';
  if (consent == true || consent == 'true') {
    var _window;
    if (process.env.NODE_ENV === "development") {
      console.log('consent updated, granted');
    }

    // Update the consent is window.gtag is available
    if (typeof window !== 'undefined' && (_window = window) !== null && _window !== void 0 && _window.gtag) {
      window.gtag('consent', 'update', {
        ad_storage: 'granted',
        analytics_storage: 'granted'
      });
    }
  } else {
    var _window2;
    if (process.env.NODE_ENV === "development") {
      console.log('consent updated, deny');
    }
    if (typeof window !== 'undefined' && (_window2 = window) !== null && _window2 !== void 0 && _window2.gtag) {
      window.gtag('consent', 'update', {
        ad_storage: 'denied',
        analytics_storage: 'denied'
      });
    }
  }
  var pluginConfig = pluginOptions.pluginConfig || {};
  var pathIsExcluded = location && typeof window.excludeGtagPaths !== "undefined" && window.excludeGtagPaths.some(function (rx) {
    return rx.test(location.pathname);
  });
  if (pathIsExcluded) return null;

  // wrap inside a timeout to make sure react-helmet is done with its changes (https://github.com/gatsbyjs/gatsby/issues/11592)
  var sendPageView = function sendPageView() {
    var _window3;
    if (process.env.NODE_ENV === "development") {
      console.log('Sending page view');
    }
    var pagePath = location ? location.pathname + location.search + location.hash : undefined;
    if (typeof window !== 'undefined' && (_window3 = window) !== null && _window3 !== void 0 && _window3.gtag) {
      window.gtag("event", "page_view", {
        page_path: pagePath
      });
    }
  };
  var _pluginConfig$delayOn = pluginConfig.delayOnRouteUpdate,
    delayOnRouteUpdate = _pluginConfig$delayOn === void 0 ? 0 : _pluginConfig$delayOn;
  if ("requestAnimationFrame" in window) {
    requestAnimationFrame(function () {
      requestAnimationFrame(function () {
        return setTimeout(sendPageView, delayOnRouteUpdate);
      });
    });
  } else {
    // Delay by 32ms to simulate 2 requestOnAnimationFrame calls
    setTimeout(sendPageView, 32 + delayOnRouteUpdate);
  }
  return null;
};