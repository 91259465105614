import fetch from 'cross-fetch'
import { ApolloClient, InMemoryCache, HttpLink } from '@apollo/client'

export const client = new ApolloClient({
  link: new HttpLink({
    uri: process.env.GATSBY_WPGRAPHQL_URL || 'https://hallinta.kalevalaseura.fi/graphql',
    // uri: process.env.WPGRAPHQL_URL || 'http://localhost/graphql',
    fetch,
  }),
  cache: new InMemoryCache(),
})
