module.exports = [{
      plugin: require('../plugins/gatsby-plugin-google-gtag-gw/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-4T7MEDE2RB"],"gtagConfig":{"anonymize_ip":true,"cookie_expires":0},"pluginConfig":{"respectDNT":true,"head":false,"exclude":[],"origin":"https://www.googletagmanager.com","delayOnRouteUpdate":0}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"start_url":"/","display":"standalone","name":"Kalevalaseura","short_name":"GW","description":"Kalevalaseura-säätiön tehtävänä on Kalevalaan ja suomalaiseen kulttuuriperintöön liittyvän tiedon välittäminen, tutkiminen ja julkaiseminen, tieteen ja taiteen yhdistäminen sekä Kalevala-aiheisen taiteen edistäminen. Järjestämme tapahtumia, myönnämme tunnustuspalkintoja, annamme lausuntoja ja teemme yhteistyötä kulttuurialan toimijoiden kanssa niin kotimaassa kuin ulkomailla. Kutsumme jäseniksemme Kalevalan, kulttuuriperinnön ja suomalaisen kulttuurin asiantuntijoita. Kalevalaseura on ainoa oman maansa kansalliseepokselle omistautunut säätiö maailmassa.","lang":"fi","icon":"src/images/favicon.png","theme_color":"#191919","background_color":"#191919","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"83b8bd3d09d6c98f7835de182acbc468"},
    },{
      plugin: require('../node_modules/gatsby-plugin-gatsby-cloud/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-source-wordpress/gatsby-browser.js'),
      options: {"plugins":[],"url":"https://hallinta.kalevalaseura.fi/graphql","schema":{"typePrefix":"Wp","queryDepth":15,"circularQueryLimit":5,"timeout":30000,"perPage":100,"requestConcurrency":15,"previewRequestConcurrency":5},"verbose":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"localeJsonSourceName":"locale","languages":["fi","en","sv"],"defaultLanguage":"fi","siteUrl":"http://localhost:8080/","redirect":false,"i18nextOptions":{"interpolation":{"escapeValue":false},"keySeparator":false,"nsSeparator":false},"pages":[{"matchPath":"/:lang?/(.*)","getLanguageFromPath":true}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-flexsearch/gatsby-browser.js'),
      options: {"plugins":[],"languages":["en"],"type":"MarkdownRemark","fields":[{"name":"title","indexed":true,"resolver":"frontmatter.title","attributes":{"encode":"balance","tokenize":"strict","threshold":6,"depth":3},"store":true},{"name":"description","indexed":true,"resolver":"frontmatter.description","attributes":{"encode":"balance","tokenize":"strict","threshold":6,"depth":3},"store":false},{"name":"url","indexed":false,"resolver":"fields.slug","store":true}]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
